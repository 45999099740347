import React from "react"
import styled from "styled-components"

const Arrow = ({ size, index }) => {
  const styles = (() => {
    switch (index) {
      case 0:
        return `
          top: 0;
          right: 0;
        `
      case 1:
        return `
          bottom: 0;
          right: 0;
          transform: rotate(90deg);
        `
      case 2:
        return `
          bottom: 0;
          left: 0;
          transform: rotate(180deg);
        `
      case 3:
        return `
          top: 0;
          left: 0;
          transform: rotate(270deg);
          border-color: #585a6c;

          &:before {
            background-color: #585a6c !important;
          }

          &:after {
            border-color: #585a6c transparent transparent transparent !important;
          }
        `
      default:
        return `
          top: 0;
          right: 0;
        `
    }
  })()

  return <StyledArrow size={size} styles={styles} />
}

export default Arrow

const StyledArrow = styled.div`
  width: ${({ size }) => size / 2 - 55}px;
  height: ${({ size }) => size / 2 - 55}px;
  border: 1px solid var(--color-white);
  border-width: 1px 1px 0 0;
  border-radius: 0 20px 0 0;
  position: absolute;
  ${({ styles }) => styles}

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: var(--color-white);
    border-radius: 20px;
    position: absolute;
    left: 0;
    top: -5px;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10.4px 6px 0 6px;
    border-color: var(--color-white) transparent transparent transparent;
    position: absolute;
    right: -6px;
    bottom: -2px;
  }
`
