import React from "react"
import styled from "styled-components"
import { Container, RelativeDiv } from "~components/Shared/Mixins"
import SectionTitle from "~components/Shared/SectionTitle"
import oval from "~assets/images/Oval.svg"
import ServiceCard from "../ServiceCard"
import useWindowDimensions from "~utilities/getDimension"

const DesktopServices = ({ className, homepageDoc, servicesDoc }) => {
  const { width } = useWindowDimensions()

  const sal = (delay) => ({
    "data-sal": "fade",
    "data-sal-delay": delay,
    "data-sal-duration": "1000",
  })

  return (
    <RelativeDiv className={className}>
      <Oval src={oval} alt="oval shape" />
      <StyledContainer>
        <SectionTitle
          title="Services"
          desc={homepageDoc.services_desc}
          serviceSection
        />

        <ServicesWrapper>
          <ServicesList1 {...sal("0")}>
            <ServiceCard item={servicesDoc["design"]} />
            <ServiceCard2 item={servicesDoc["future"]} />
            {width < 900 && <ServiceCard item={servicesDoc["cloud"]} />}
          </ServicesList1>

          <ServicesList2 {...sal("400")}>
            <ServiceCard item={servicesDoc["develop"]} />
            <ServiceCard2 item={servicesDoc["growth"]} />
            {width < 900 && <ServiceCard2 item={servicesDoc["qa"]} />}
          </ServicesList2>

          {width >= 900 && (
            <ServicesList3 {...sal("800")}>
              <ServiceCard item={servicesDoc["cloud"]} />
              <ServiceCard2 item={servicesDoc["qa"]} />
            </ServicesList3>
          )}
        </ServicesWrapper>
      </StyledContainer>
    </RelativeDiv>
  )
}

export default DesktopServices

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  overflow: visible;
`

const Oval = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -3;
`

const ServicesWrapper = styled.div`
  display: flex;
`

const ServiceCard2 = styled(ServiceCard)`
  margin-top: 10px;
`

const ServicesList1 = styled.div`
  margin-right: 10px;
`

const ServicesList2 = styled.div`
  margin-right: 10px;
  margin-top: 20px;
`

const ServicesList3 = styled.div`
  margin-top: 40px;
`
