import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Container } from "~components/Shared/Mixins"
import { RichText } from "prismic-reactjs"
import SectionTitle from "~components/Shared/SectionTitle"
import Icon from "~assets/icon"
import { tablet, mobile } from "~utilities/mediaQuery"
import Ellipsis from "react-ellipsis-pjs"

const Partners = () => {
  // fetch data
  const { prismic } = useStaticQuery(
    graphql`
      {
        prismic {
          homepage: allHomepages {
            edges {
              node {
                partners_title
                partners_desc
                partners_item {
                  desc
                  identify
                  name
                  subtitle
                }
              }
            }
          }
        }
      }
    `
  )
  const doc = prismic.homepage.edges[0].node

  const [mobileExpand, setMobileExpand] = useState([false, false, false])

  const handleClick = (i) => {
    const newArray = mobileExpand.map((item, index) =>
      index === i ? !item : item
    )
    setMobileExpand(newArray)
  }

  return (
    <Container id="anchor-partners">
      <SectionTitle title={doc.partners_title} desc={doc.partners_desc} />

      <PartnerWrapper>
        {doc.partners_item.map((item, index) => (
          <PartnerItem
            key={item.identify}
            className={mobileExpand[index] && "expand"}
            onClick={() => handleClick(index)}
            data-sal="slide-up"
            data-sal-delay={index * 300}
            data-sal-duration="1000"
          >
            <StyledIcon>
              <Icon icon={`partner-${item.identify}`} width="24" />
              <div></div>
            </StyledIcon>
            <div>
              <h4>{item.name}</h4>
              <span>{item.subtitle}</span>
              <Content className="desktop">
                {RichText.asText(item.desc)}
              </Content>
              <Content className="mobile">
                {!mobileExpand[index] && (
                  <Ellipsis lines={2} text={RichText.asText(item.desc)} />
                )}
                <div className={`full ${mobileExpand[index] && "active"}`}>
                  {RichText.asText(item.desc)}
                </div>
              </Content>
            </div>
          </PartnerItem>
        ))}
      </PartnerWrapper>
    </Container>
  )
}

export default Partners

const PartnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto 100px;
  padding: 0 24px;

  ${tablet} {
    padding: 0 3%;
    margin: 0 auto 100px;
  }

  ${mobile} {
    flex-direction: column;
    padding: 0;
    margin: 0 auto;
  }
`

const PartnerItem = styled.div`
  max-width: 310px;
  width: 310px;
  height: 200px;
  border-radius: 3px;
  background-image: linear-gradient(to bottom, #292c3b, var(--color-bg-1) 88%);
  padding: 0 10px;
  text-align: center;

  ${tablet} {
    width: 31%;
  }

  ${mobile} {
    width: 100%;
    display: flex;
    padding: 16px 20px;
    text-align: left;
    margin-bottom: 30px;
    height: unset;
    max-height: 85px;
    overflow: hidden;
    transition: max-height 1.2s;

    &.expand {
      max-height: 200px;
    }
  }

  h4 {
    color: var(--color-white);
    font-size: 20px;
    font-family: "JetBrains Mono", sans-serif;
    margin: 0 0 3px;

    ${mobile} {
      font-size: 14px;
    }
  }

  span {
    display: block;
    margin-bottom: 8px;
    font-size: 13px;
    font-family: "JetBrains Mono", sans-serif;

    ${mobile} {
      font-size: 10px;
    }
  }
`

const Content = styled.div`
  color: var(--color-white);
  line-height: 2;
  font-size: 14px;

  &.mobile {
    display: none;
  }

  ${mobile} {
    font-size: 12px;
    line-height: 1.4;

    &.desktop {
      display: none;
    }

    &.mobile {
      display: block;
    }

    .full {
      opacity: 0;
      transform: translateY(-32px);
      transition: opacity 1.2s;

      &.active {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
`

const StyledIcon = styled.div`
  width: 32px;
  height: 32px;
  margin: 0 auto 5px;
  border-radius: 50px;
  background-color: #43475b;
  position: relative;
  top: -16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mobile} {
    top: 0;
    margin: 20px 20px 0 0;
    flex-shrink: 0;
  }

  svg {
    margin-top: -2px;
  }

  div {
    width: 24px;
    height: 10px;
    filter: blur(5px);
    background-color: #ff596b;
    margin: 0 auto;
  }
`
