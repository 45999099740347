import React from "react"
import styled from "styled-components"
import { tablet } from "~utilities/mediaQuery"

const Highlight = (props) => (
  <HighlightWrapper {...props}>
    <Circle>
      <Text {...props}>{props.text}</Text>
    </Circle>
  </HighlightWrapper>
)

export default Highlight

const HighlightWrapper = styled.div`
  position: absolute;
  top: ${(props) => props.coordinate[0] || "unset"};
  right: ${(props) => props.coordinate[1] || "unset"};
  bottom: ${(props) => props.coordinate[2] || "unset"};
  left: ${(props) => props.coordinate[3] || "unset"};
`

const Circle = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  border: solid 1px rgba(147, 148, 154, 0.25);
  transition: border-color 1s;
  will-change: border-color;

  &:hover {
    border-color: var(--color-white);
  }

  ${tablet} {
    width: 14px;
    height: 14px;
  }
`

const Text = styled.span`
  position: absolute;
  font-size: 15px;
  line-height: 25px;
  width: 285px;
  text-align: ${(props) => (props.left ? "right" : "left")};
  right: ${(props) => (props.left ? "15px" : "unset")};
  top: ${(props) => (props.bottom ? "5px" : "unset")};
  bottom: ${(props) => (props.top ? "-10px" : "unset")};
  left: ${(props) => (props.right ? "14px" : "unset")};
  transition: color 1s;
  will-change: color;

  &:hover {
    color: var(--color-white);
  }

  ${tablet} {
    font-size: 13px;
    line-height: 20px;
    width: 230px;
    right: ${(props) => (props.left ? "13px" : "unset")};
    top: ${(props) => (props.bottom ? "4px" : "unset")};
    bottom: ${(props) => (props.top ? "-12px" : "unset")};
  }
`
