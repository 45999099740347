import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Container } from "~components/Shared/Mixins"
import SectionTitle from "~components/Shared/SectionTitle"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { tablet, mobile } from "~utilities/mediaQuery"
import waveBg from "~assets/images/background-wave.svg"
import waveBgMobile from "~assets/images/background-wave-mobile.svg"
import { RichText } from "prismic-reactjs"
import Button from "~components/Shared/Button"
import Icon from "~assets/icon"
import ContactForm from "./ContactForm"

const Contact = () => {
  // fetch data
  const { prismic } = useStaticQuery(
    graphql`
      {
        prismic {
          homepage: allHomepages {
            edges {
              node {
                connect_cta
                contact_desc
                contact_title
                thanks_content
              }
            }
          }
        }
      }
    `
  )
  const doc = prismic.homepage.edges[0].node

  const [step, setStep] = useState(0)

  const nextStep = () => setStep(step + 1)

  return (
    <Wrapper id="anchor-start">
      <div data-sal="fade" data-sal-delay="500" data-sal-duration="1000">
        <WaveBg src={waveBg} alt="Wave Background" />
        <WaveBg
          src={waveBgMobile}
          alt="Wave Background"
          className="mobile-bg"
        />
      </div>

      <StyledContainer>
        <SectionTitle title={doc.contact_title} desc={doc.contact_desc} />
        <TransitionGroup>
          <CSSTransition key={step} classNames="fade" timeout={1000} appear>
            <SwitchSection>
              {step === 0 && (
                <StepWrapper
                  data-sal="slide-left"
                  data-sal-delay="500"
                  data-sal-duration="1000"
                >
                  <h3>
                    Hello! <br />I am so glad to see you here.
                  </h3>
                  <Button content={doc.connect_cta} onClick={nextStep} />
                </StepWrapper>
              )}

              {step === 1 && (
                <StepWrapper>
                  <ContactForm nextStep={nextStep} />
                </StepWrapper>
              )}

              {step === 2 && (
                <StepWrapper>
                  <Icon icon="smile" width="24" className="logo-icon" />
                  <h3>
                    <RichText render={doc.thanks_content} />
                  </h3>
                </StepWrapper>
              )}
            </SwitchSection>
          </CSSTransition>
        </TransitionGroup>
      </StyledContainer>
    </Wrapper>
  )
}

export default Contact

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: var(--color-button-text);
  overflow: hidden;

  .fade-enter {
    opacity: 0;
    transform: translateX(-100px);
  }

  .fade-enter-active {
    opacity: 1;
    transform: translateX(0px);
    transition: opacity 700ms linear, transform 500ms linear;
    transition-delay: 300ms;
    will-change: opacity, transform;
  }

  .fade-exit {
    opacity: 1;
    transform: translateX(0px);
  }

  .fade-exit-active {
    opacity: 0;
    transform: translateX(100px);
    transition: opacity 600ms ease-out, transform 600ms ease-out;
    will-change: opacity, transform;
  }
`

const StyledContainer = styled(Container)`
  min-height: 100vh;
`

const WaveBg = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0.8;

  &.mobile-bg {
    display: none;
  }

  ${tablet} {
    display: none;

    &.mobile-bg {
      display: block;
    }
  }
`

const SwitchSection = styled.div`
  width: 90%;
  position: relative;
  margin: 50px auto 0;

  ${mobile} {
    width: 100%;
    margin-top: 0;
  }
`

const StepWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  ${mobile} {
    .logo-icon {
      margin-bottom: 20px;
    }
  }

  h3 {
    color: var(--color-white);
    font-size: 42px;
    font-family: "Hind Siliguri", Arial, Helvetica, sans-serif;
    font-weight: bold;
    line-height: 1.43;

    ${mobile} {
      font-size: 36px;
      line-height: 1.1;
    }

    p {
      margin: 0;
      color: inherit;
    }
  }
`
