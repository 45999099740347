import React from "react"
import styled from "styled-components"

const Indicators = ({ activeIndex }) => {
  return (
    <Wrapper>
      {[0, 1, 2, 3].map((item) => (
        <Dot active={item === activeIndex} key={item} />
      ))}
    </Wrapper>
  )
}

export default Indicators

const Wrapper = styled.div`
  width: 56px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 20px;
  background-color: ${({ active }) =>
    active ? "var(--color-white)" : "#4d5063"};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.32);
  transition: background-color 0.7s;
  will-change: background-color;
`
