import React, { useState } from "react"
import styled from "styled-components"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import Icon from "~assets/icon"
import Email from "./Email"
import Purpose from "./Purpose"
import Message from "./Message"
import crmApiRequest from "./crm"

const ContactForm = ({ nextStep }) => {
  const [step, setStep] = useState(0)
  const goNext = () => setStep(step + 1)

  const [email, setEmail] = useState("")
  const [purpose, setPurpose] = useState([])
  const [message, setMessage] = useState("")

  const titles = [
    "What is your email?",
    "What do you need?",
    "Wanna tell us more?",
  ]

  const submit = () => {
    crmApiRequest(email, purpose.toString(), message, (xhr) => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        nextStep()
      } else if (xhr.readyState === 4) {
        const data = JSON.parse(xhr.responseText)
        data.errors.forEach((item) => {
          if (item.errorType === "INVALID_EMAIL") {
            console.log(`Bad Email => ${email}`)
          } else {
            console.log(item)
          }
        })
      }
    })
  }

  return (
    <div>
      <Indicator>
        <span className="current">0{step + 1}</span>
        <span className="slash">/</span>
        <span>0{titles.length}</span>
      </Indicator>

      <FieldTitle>
        {step + 1}
        <Icon icon="arrow" width="8" />
        <span>{titles[step]}</span>
      </FieldTitle>

      <TransitionGroup>
        <CSSTransition key={step} classNames="fade" timeout={1000} appear>
          <Wrapper>
            {step === 0 && (
              <StepWraper>
                <Email goNext={goNext} returnDate={(data) => setEmail(data)} />
              </StepWraper>
            )}

            {step === 1 && (
              <StepWraper>
                <Purpose
                  goNext={goNext}
                  returnDate={(data) => setPurpose(data)}
                />
              </StepWraper>
            )}

            {step === 2 && (
              <StepWraper>
                <Message
                  goNext={submit}
                  returnDate={(data) => setMessage(data)}
                />
              </StepWraper>
            )}
          </Wrapper>
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
}

export default ContactForm

const Indicator = styled.div`
  margin-bottom: 20px;

  span {
    margin: 0;
    font-size: 15px;
    height: 17px;
    line-height: 17px;
  }

  .current {
    color: var(--color-white);
  }

  .slash {
    display: inline-block;
    margin: 0 8px;
    font-size: 6px;
    font-weight: bold;
    color: var(--color-white);
  }
`

const FieldTitle = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  color: var(--color-white);
  margin-bottom: 18px;

  svg {
    margin: 0 5px;
  }

  span {
    font-weight: bold;
    margin: 0;
    color: var(--color-white);
  }
`

const Wrapper = styled.div`
  margin-left: 20px;
  position: relative;
`

const StepWraper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`
