import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import DesktopProcess from "./Desktop"
import MobileProcess from "./Mobile"

const Process = () => {
  // fetch data
  const { prismic } = useStaticQuery(
    graphql`
      {
        prismic {
          homepage: allHomepages {
            edges {
              node {
                process_title
                process_desc
                processes {
                  color
                  desc
                  title
                }
              }
            }
          }
        }
      }
    `
  )
  const doc = prismic.homepage.edges[0].node

  // determine which step is active
  const [activeIndex, setActiveIndex] = useState(0)

  // used to restart the interval after user click step
  const [reset, setReset] = useState(0)
  useInterval(
    () => {
      setActiveIndex(
        activeIndex >= doc.processes.length - 1 ? 0 : activeIndex + 1
      )
    },
    4000,
    reset
  )

  const handleClick = (i) => {
    setActiveIndex(i)
    setReset(i)
  }

  return (
    <div id="anchor-processes">
      <DesktopProcess
        doc={doc}
        activeIndex={activeIndex}
        handleClick={handleClick}
        className="render-over-mobile"
      />

      <MobileProcess
        doc={doc}
        activeIndex={activeIndex}
        handleClick={handleClick}
        className="render-below-mobile"
      />
    </div>
  )
}

export default Process

function useInterval(callback, delay, reset) {
  const savedCallback = useRef()
  const savedReset = useRef()
  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback
    savedReset.current = reset
  }, [callback, reset])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null || reset !== savedReset.current) {
      let id = setInterval(tick, delay)
      return () => {
        clearInterval(id)
      }
    }
  }, [delay, reset])
}
