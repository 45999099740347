import React, { useState } from "react"
import styled from "styled-components"
import Button from "~components/Shared/Button"
import Icon from "~assets/icon"

const options = [
  "I need a competent designer.",
  "I need a developer for my project.",
  "I’m reaching out to learn more about Moozy.",
  "I have an idea, but need some help getting started.",
]

const Purpose = ({ goNext, returnDate }) => {
  const [purpose, setPurpose] = useState([0, 0, 0, 0])

  const handleClick = (givenIndex) => {
    const newPurpose = purpose.map((item, index) => {
      if (index === givenIndex) return !item
      return item
    })
    setPurpose(newPurpose)
  }

  const nextStep = () => {
    let data = []
    purpose.forEach((item, index) => item && data.push(options[index]))
    returnDate(data)
    goNext()
  }

  return (
    <>
      <Wrapper>
        {options.map((item, index) => (
          <Option
            key={index}
            className={purpose[index] && "active"}
            onClick={() => handleClick(index)}
          >
            <p>{item}</p>
            <Icon icon="check" width="16" />
          </Option>
        ))}
      </Wrapper>

      <Button content="Next" onClick={nextStep} />
    </>
  )
}

export default Purpose

const Wrapper = styled.div`
  margin-bottom: 20px;
`

const Option = styled.div`
  position: relative;
  width: 300px;
  padding: 7px 11px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #424456;
  border-radius: 6px;
  cursor: pointer;
  border: solid 1px transparent;
  transition: border 0.5s;
  will-change: border;

  &.active {
    border-color: var(--color-white);

    svg {
      opacity: 1;
    }
  }

  p {
    max-width: 250px;
    font-size: 15px;
    margin: 0;
    color: var(--color-white);
  }

  svg {
    color: var(--color-white);
    opacity: 0;
    transition: opacity 0.5s;
    will-change: opacity;
  }
`
