import React from "react"
import styled from "styled-components"
import { Container } from "~components/Shared/Mixins"
import { RichText } from "prismic-reactjs"
import SectionTitle from "~components/Shared/SectionTitle"
import { tablet } from "~utilities/mediaQuery"

const DesktopProcess = ({ doc, activeIndex, handleClick, className }) => (
  <Wrapper className={className}>
    <Container>
      <SectionTitle title={doc.process_title} desc={doc.process_desc} />
      <UpperArrow />
      <StepWrapper>
        {doc.processes.map((item, index, arr) => (
          <React.Fragment key={item.title}>
            <StepTitle
              color={item.color}
              className={index === activeIndex && "active"}
              onClick={() => handleClick(index)}
            >
              <span>{item.title}</span>
              <RichText render={item.desc} />
            </StepTitle>
            {arr.length - 1 !== index && <Arrow />}
          </React.Fragment>
        ))}
      </StepWrapper>
    </Container>
  </Wrapper>
)

export default DesktopProcess

const bgColor = "#252634"
const greyDot = "#585a6c"

const Wrapper = styled.div`
  width: 100%;
  background-color: ${bgColor};
  padding-bottom: 150px;

  ${Container} {
    overflow: visible;
  }
`

const UpperArrow = styled.div`
  width: calc(95% - 96px);
  height: 60px;
  margin: 50px auto 0;
  position: relative;
  top: -20px;
  background: linear-gradient(to right, ${bgColor} 33%, ${greyDot} 0%) top/10px
      1px repeat-x,
    linear-gradient(${bgColor} 33%, ${greyDot} 0%) right/1px 10px repeat-y,
    linear-gradient(to right, ${bgColor} 33%, ${bgColor} 0%) bottom/10px 1px
      repeat-x,
    linear-gradient(${bgColor} 33%, ${greyDot} 0%) left/1px 10px repeat-y;

  ${tablet} {
    width: calc(85% - 88px);
  }

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: ${greyDot};
    border-radius: 20px;
    position: absolute;
    right: -3.5px;
    bottom: -5px;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10.4px 6px 0 6px;
    border-color: ${greyDot} transparent transparent transparent;
    position: absolute;
    left: -5px;
    bottom: -6px;
  }
`

const StepWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  position: relative;

  ${tablet} {
    width: 85%;
  }
`

const StepTitle = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 200px;
  background-color: var(--color-bg-1);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  transition: background-color 0.8s, box-shadow 0.8s;
  will-change: background-color, box-shadow;
  cursor: pointer;

  ${tablet} {
    width: 88px;
    height: 88px;
    padding-top: 37px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: var(--color-white);
    margin: 0 0 55px;
    user-select: none;
  }

  p {
    margin: 0;
    font-size: 13px;
    line-height: 1.54;
    font-family: "SF Pro", Arial, Helvetica, sans-serif;
    width: 250px;
    text-align: center;
    transition: color 0.8s;
    will-change: color;

    ${tablet} {
      width: 170px;
    }
  }

  &.active {
    background-color: ${(props) => props.color};
    box-shadow: 0 4px 8px 0
      ${(props) => {
        const regex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
        const result = regex.exec(props.color)
        return result
          ? `rgba(${parseInt(result[1], 16)}, 
          ${parseInt(result[2], 16)}, 
          ${parseInt(result[3], 16)}, 0.48)`
          : null
      }};

    p {
      color: var(--color-white);
    }
  }
`

const Arrow = styled.div`
  flex: 1;
  height: 1px;
  margin: 0 20px;
  position: relative;
  background-color: var(--color-white);

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: var(--color-white);
    border-radius: 20px;
    position: absolute;
    left: 0;
    top: -4.5px;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 10.4px;
    border-color: transparent transparent transparent var(--color-white);
    position: absolute;
    right: -2px;
    top: -6px;
  }
`
