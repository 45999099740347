import React, { useState } from "react"
import styled from "styled-components"
import { Container, RelativeDiv } from "~components/Shared/Mixins"
import SectionTitle from "~components/Shared/SectionTitle"
import ServiceCard from "../ServiceCard"
import { useSpring, animated } from "react-spring"
import { useDrag } from "react-use-gesture"
import { scrollToEleById } from "~utilities/scroll"

const MobileServices = ({ className, homepageDoc, servicesDoc }) => {
  const list = ["design", "develop", "cloud", "future", "growth", "qa"]

  const [expand, setExpand] = useState(false)
  // under expand mode, indicate which card is highlighted
  const [active, setActive] = useState(0)
  // spring animation to drag the container
  const [{ x }, setSpring] = useSpring(() => ({
    x: 0,
  }))

  const bind = useDrag(
    ({ down, movement: [mx], direction: [xDir], velocity, cancel }) => {
      const trigger = velocity > 0.3
      const overHalf = mx > 140 || mx < -140
      const dir = xDir < 0 ? 1 : -1
      // make sure he list is expand
      if (expand) {
        const distance = active * -300 + mx * 0.5
        if (distance <= 100 && distance > -1600) {
          setSpring(() => {
            let curActive = active
            if (!down && (trigger || overHalf)) {
              const newActive = active + dir
              if (newActive >= 0 && newActive <= 5) {
                // another hack :(
                // setActive is async but I can't realtime data for the returned value
                // optimize if you can!
                curActive = newActive
                setActive(newActive)
              }
            }
            return {
              x: down ? distance : curActive * -300,
            }
          })
        } else {
          setSpring({ x: distance > 0 ? 0 : -1500 })
        }
      } else if (trigger) {
        scrollToEleById("anchor-services", 0)
        setExpand(true)
      }
    }
  )

  const handleClick = (item) => {
    if (item === "design") {
      scrollToEleById("anchor-services", 0)
      setExpand(!expand)
    }
  }

  return (
    <Relative className={className}>
      <BackgroundShape />
      <Container>
        <SectionTitle
          title="Services"
          desc={homepageDoc.services_desc}
          serviceSection
        />

        <animated.div
          {...bind()}
          style={{
            position: "relative",
            minHeight: "350px",
            width: `${expand ? 1800 : 360}px`,
            margin: "0 auto",
            touchAction: `${expand ? "none" : "unset"}`,
            transform: x.interpolate((x) => `translate3d(${x}px, 0, 0)`),
          }}
        >
          {list.map((item, index) => (
            <CardWrapper index={index} expand={expand} key={item}>
              <ServiceCard
                item={servicesDoc[item]}
                mask={index !== active ? 1 : 0}
                onClick={() => handleClick(item)}
              />
            </CardWrapper>
          ))}
        </animated.div>
      </Container>
    </Relative>
  )
}

export default MobileServices

const Relative = styled(RelativeDiv)`
  overflow: hidden;
`

const BackgroundShape = styled.div`
  position: absolute;
  top: -30px;
  width: 100%;
  height: 450px;
  border-radius: 32px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  background-image: linear-gradient(to bottom, #080924 21%, #22222c 82%);
`

const CardWrapper = styled.div`
  position: absolute;
  z-index: ${(props) => 10 - props.index};
  transform: ${({ index, expand }) => {
    const x = index * (expand ? 300 : 15)
    const s = expand ? 1 : 1 - index * 0.035
    return `translate3d(${x}px, 0, 0) scale(${s})`
  }};
  transition: transform 1s;
  will-change: transform;
`
