import React, { useState } from "react"
import Button from "~components/Shared/Button"
import Input from "~components/Shared/Input"
import verifyEmail from "~utilities/verifyEmail"

const Email = ({ goNext, returnDate }) => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState(false)

  const checkEmail = () => {
    if (verifyEmail(email)) {
      returnDate(email)
      goNext()
    } else {
      setError(!error)
    }
  }
  return (
    <>
      <Input
        placeholder="email@mail.com"
        type="email"
        onChange={setEmail}
        invalid={error}
      />
      <Button content="Next" onClick={checkEmail} />
    </>
  )
}

export default Email
