import React from "react"

import Layout from "~components/Layout"
import Header from "~components/sections/Header"
import Services from "~components/sections/Services"
import Why from "~components/sections/Why"
import Process from "~components/sections/Process"
import Partners from "~components/sections/Partners"
import Contact from "~components/sections/Contact"

const IndexPage = () => {
  return (
    <Layout title="Home">
      <Header />
      <Services />
      <Why />
      <Process />
      <Partners />
      <Contact />
    </Layout>
  )
}

export default IndexPage
