import React from "react"
import styled from "styled-components"
import { tablet, mobile } from "~utilities/mediaQuery"

const SectionTitle = ({ title, desc, ...props }) => (
  <Wrapper {...props}>
    <BackgroundTitle {...props} data-sal="fade" data-sal-duration="800">
      {title}
    </BackgroundTitle>
    <Title data-sal="slide-up" data-sal-delay="100" data-sal-duration="500">
      {title}
    </Title>
    <Divider />
    <Desc data-sal="slide-up" data-sal-delay="100" data-sal-duration="500">
      {desc}
    </Desc>
  </Wrapper>
)
export default SectionTitle

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;
  padding-top: ${(props) => (props.serviceSection ? "200px" : "100px")};
  padding-bottom: 100px;

  ${tablet} {
    padding-bottom: 75px;
  }

  ${mobile} {
    text-align: left;
    align-items: flex-start;
    padding-top: ${(props) => (props.serviceSection ? "140px" : "100px")};
    padding-bottom: 40px;
  }
`

const Title = styled.h3`
  color: var(--color-white);
  box-shadow: 0px 2px 0px 0px var(--color-red);
  z-index: 1;

  ${mobile} {
    padding-bottom: 5px;
    margin-bottom: 8px;
  }
`

const Divider = styled.div`
  display: none;

  ${mobile} {
    display: block;
    position: relative;
    top: -8px;
    left: -24px;
    width: 100vh;
    height: 1px;
    background-color: #2d2f36;
  }
`

const Desc = styled.span`
  max-width: 360px;
  display: block;
  font-size: 12px;
  font-family: "JetBrains Mono", sans-serif;
`

const BackgroundTitle = styled.h2`
  position: absolute;
  top: ${(props) => (props.serviceSection ? "149px" : "49px")};
  font-size: 106px;
  z-index: 1;
  letter-spacing: 1px;
  background-image: linear-gradient(
    180deg,
    rgba(87, 90, 112, 0.6) 0%,
    transparent 75%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  pointer-events: none;

  ${mobile} {
    top: ${(props) => (props.serviceSection ? "95px" : "55px")};
    left: -16px;
    white-space: nowrap;
    background-image: linear-gradient(
      to bottom,
      #575a70 20%,
      rgba(51, 54, 70, 0) 70%
    );
  }
`
