import React from "react"
import styled from "styled-components"
import { Container } from "~components/Shared/Mixins"
import SectionTitle from "~components/Shared/SectionTitle"
import { Blob } from "react-blob"
import oval from "~assets/images/blob.svg"

class Position {
  constructor(flameTop, flameLeft, ovalTop, ovalLeft, ovalRotate) {
    this.flameTop = flameTop + "px"
    this.flameLeft = flameLeft + "px"
    this.flameRotate = Math.random() * 180 + "deg"
    this.ovalTop = ovalTop + "px"
    this.ovalLeft = ovalLeft + "px"
    this.ovalRotate = ovalRotate + "deg"
  }
}

const MobileWhy = ({ doc, className }) => {
  return (
    <Container className={className}>
      <SectionTitle title={doc.why_title} desc={doc.why_desc} />

      <div>
        {doc.why_features.map((item, index) => {
          const blobSize = 50 + Math.random() * 5 + "px"
          const positions = (() => {
            switch (index) {
              case 0:
                return new Position(-2, 2, 20, 0, -32)
              case 1:
                return new Position(5, 10, 0, 0, -120)
              case 2:
                return new Position(6, -5, 2, 20, -120)
              case 3:
                return new Position(-3, -4, 22, 22, -45)
              case 4:
                return new Position(8, -3, 5, 14, 30)
              default:
                return new Position(0, 0, 0, 0, 0)
            }
          })()

          return (
            <div key={index}>
              <div
                data-sal="fade"
                data-sal-delay="500"
                data-sal-duration="1000"
              >
                <StyledBlob size={blobSize} index={index}>
                  <InnerFlame {...positions} />
                  <Oval src={oval} alt="oval" {...positions} />
                </StyledBlob>
              </div>

              <Text
                data-sal="fade"
                data-sal-delay="500"
                data-sal-duration="1000"
              >
                {item.feature}
              </Text>
            </div>
          )
        })}
      </div>
    </Container>
  )
}

export default MobileWhy

const Text = styled.p`
  font-size: 13px;
  line-height: 1.4;
  color: var(--color-white);
  margin-top: 24px;
  margin-bottom: 20px;
`

const StyledBlob = styled(Blob)`
  background-color: #43475b;
  overflow: visible;
  position: relative;
  left: ${({ index }) => index * 20 + "%"};
`

const InnerFlame = styled.div`
  width: 32px;
  height: 25px;
  filter: blur(7px);
  background-color: #ff596b;
  border-radius: 50px;
  position: relative;
  transform: rotate(${({ flameRotate }) => flameRotate});
  top: ${({ flameTop }) => flameTop};
  left: ${({ flameLeft }) => flameLeft};
`

const Oval = styled.img`
  position: absolute;
  transform: rotate(${({ ovalRotate }) => ovalRotate});
  top: ${({ ovalTop }) => ovalTop};
  left: ${({ ovalLeft }) => ovalLeft};
`
