/**
 *
 * Current CRM is using HubSpot
 * Contact List: https://app.hubspot.com/forms/8123641/6ae0cca8-860d-4ed6-9e66-4eb7a0b7098d/submissions
 *
 * @param {string} email
 * @param {string} purpose
 * @param {string} message
 * @param {CallableFunction} callback
 */
const crmApiRequest = (email, purpose, message, callback) => {
  var xhr = new XMLHttpRequest()
  var url =
    "https://api.hsforms.com/submissions/v3/integration/submit/8123641/6ae0cca8-860d-4ed6-9e66-4eb7a0b7098d"

  var data = {
    submittedAt: Date.now(),
    fields: [
      {
        name: "email",
        value: email,
      },
      {
        name: "purpose",
        value: purpose,
      },
      {
        name: "message",
        value: message,
      },
    ],
  }

  var final_data = JSON.stringify(data)

  xhr.open("POST", url)
  xhr.setRequestHeader("Content-Type", "application/json")

  xhr.onreadystatechange = function () {
    callback(xhr)
  }
  xhr.send(final_data)
}

export default crmApiRequest
