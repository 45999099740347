import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import DesktopService from "./Desktop"
import MobileService from "./Mobile"

const Header = ({ props }) => {
  // fetch data
  const {
    prismic: { homepage, services },
  } = useStaticQuery(
    graphql`
      {
        prismic {
          homepage: allHomepages {
            edges {
              node {
                service_cta
                services_desc
              }
            }
          }
          services: allServicess {
            edges {
              node {
                description
                title
                identifier
                body {
                  ... on PRISMIC_ServicesBodySub_services {
                    primary {
                      sub_service_description
                      sub_service_title
                    }
                    fields {
                      tools
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const homepageDoc = homepage.edges[0] && homepage.edges[0].node
  const servicesDoc = services.edges
  if (!homepageDoc || !servicesDoc)
    throw Error("Cannot Fetch Nodes from Services Component, Check CMS Source.")

  let servicesItems = {}

  servicesDoc.forEach((item) => {
    let service = {
      id: item.node.identifier,
      title: item.node.title,
      desc: item.node.description,
    }
    // assign assets
    service.assets = assets[service.id]
    // iterate sub services
    let subServices = []
    item.node.body.forEach((serv) => {
      let subService = {
        title: serv.primary.sub_service_title,
        desc: serv.primary.sub_service_description,
      }
      subService.tools = serv.fields.map((tool) => tool.tools)
      subServices.push(subService)
    })
    service.subServices = subServices
    servicesItems[service.id] = service
  })

  return (
    <div id="anchor-services">
      <DesktopService
        homepageDoc={homepageDoc}
        servicesDoc={servicesItems}
        className="render-over-mobile"
        {...props}
      />

      <MobileService
        homepageDoc={homepageDoc}
        servicesDoc={servicesItems}
        className="render-below-mobile"
      />
    </div>
  )
}

export default Header

const assets = {
  design: {
    icon: "service-design",
    texture: "Texture1.svg",
    color: "#ea1eff",
  },
  develop: {
    icon: "service-web",
    texture: "Texture2.svg",
    color: "#027fdd",
  },
  cloud: {
    icon: "service-cloud",
    texture: "Texture3.svg",
    color: "#eacb28",
  },
  future: {
    icon: "service-future",
    texture: "Texture4.svg",
    color: "#f64538",
  },
  growth: {
    icon: "service-growth",
    texture: "Texture5.svg",
    color: "#ecae03",
  },
  qa: {
    icon: "service-qa",
    texture: "Texture6.svg",
    color: "#3fd674",
  },
}
