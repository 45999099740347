import React, { useState } from "react"
import Button from "~components/Shared/Button"
import Textarea from "~components/Shared/Textarea"

const Message = ({ goNext, returnDate }) => {
  const [message, setMessage] = useState("")
  const [disable, setDisable] = useState(false)
  const [error, setError] = useState("")

  const handleClick = () => {
    if (message) {
      setDisable(true)
      goNext()
    } else {
      setError(true)
    }
  }

  const handleChange = (data) => {
    setMessage(data)
    returnDate(data)
  }

  return (
    <>
      <Textarea
        placeholder="Anything is welcome!"
        onChange={handleChange}
        invalid={error}
      />
      <Button
        theme="blue"
        icon="check"
        content="Next"
        disable={disable}
        onClick={handleClick}
      />
    </>
  )
}

export default Message
