import React, { useState } from "react"
import styled from "styled-components"

const Textarea = ({ placeholder, onChange, ...props }) => {
  const [value, setValue] = useState("")
  const [row, setRow] = useState(1)

  const handleChange = (event) => {
    const textareaLineHeight = 20
    const minRows = 1
    const maxRows = 10

    const previousRows = event.target.rows
    event.target.rows = minRows

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight)

    if (currentRows === previousRows) {
      event.target.rows = currentRows
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows
      event.target.scrollTop = event.target.scrollHeight
    }

    setValue(event.target.value)
    onChange(event.target.value)
    setRow(currentRows < maxRows ? currentRows : maxRows)
  }

  return (
    <StyledTextarea
      rows={row}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      {...props}
    />
  )
}
export default Textarea

const StyledTextarea = styled.textarea`
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  resize: none;
  overflow: auto;

  font-size: 16px;
  line-height: 20px;
  font-family: "SF Pro", Arial, Helvetica, sans-serif;
  border-bottom: 1px solid ${(props) => (props.invalid ? "#fb2841" : "#424456")};
  padding-bottom: 6px;
  margin-bottom: 20px;
  color: var(--color-white);
  width: 300px;
  transition: border-color 0.5s;
  will-change: border-color;

  &:focus {
    outline: none;
  }
`
