import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import DesktopWhy from "./Desktop"
import MobileWhy from "./Mobile"

const Why = () => {
  // fetch data
  const { prismic } = useStaticQuery(
    graphql`
      {
        prismic {
          homepage: allHomepages {
            edges {
              node {
                why_desc
                why_features {
                  feature
                }
                why_title
              }
            }
          }
        }
      }
    `
  )
  const doc = prismic.homepage.edges[0].node

  return (
    <div id="anchor-why">
      <DesktopWhy doc={doc} className="render-over-mobile" />
      <MobileWhy doc={doc} className="render-below-mobile" />
    </div>
  )
}

export default Why
