import React, { useState } from "react"
import styled from "styled-components"

const Input = ({ placeholder, type, onChange, ...props }) => {
  const [value, setValue] = useState("")

  const updateValue = (e) => {
    setValue(e.target.value)
    onChange(e.target.value)
  }

  return (
    <StyledInput
      value={value}
      onChange={updateValue}
      type={type}
      placeholder={placeholder}
      {...props}
    />
  )
}
export default Input

const StyledInput = styled.input`
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;

  font-size: 16px;
  font-family: "SF Pro", Arial, Helvetica, sans-serif;
  border-bottom: 1px solid ${props => props.invalid ? "#fb2841" : "#424456"};
  padding-bottom: 6px;
  margin-bottom: 20px;
  color: var(--color-white);
  width: 300px;
  transition: border-color 0.5s;
  will-change: border-color;

  &:focus {
    outline: none;
  }
`
