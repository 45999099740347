import React from "react"
import styled from "styled-components"
import { Container, RelativeDiv } from "~components/Shared/Mixins"
import SectionTitle from "~components/Shared/SectionTitle"
import texture from "~assets/images/Why.svg"
import Highlight from "./Highlight"
import { tablet } from "~utilities/mediaQuery"
import useWindowDimensions from "~utilities/getDimension"

const DesktopWhy = ({ doc, className }) => {
  const { width } = useWindowDimensions()

  const sal = (dir) => ({
    "data-sal": `slide-${dir}`,
    "data-sal-delay": "500",
    "data-sal-duration": "1000",
  })

  return (
    <Container className={className}>
      <SectionTitle title={doc.why_title} desc={doc.why_desc} />

      <TextWrapper>
        <Texture
          src={texture}
          alt="WHY Text"
          data-sal="fade"
          data-sal-duration="1000"
        />
        <Highlight
          text={doc.why_features[1].feature}
          coordinate={
            width > 1024 ? ["-12px", 0, 0, "160px"] : ["-11px", 0, 0, "99px"]
          }
          top
          left
          {...sal("right")}
        ></Highlight>

        <Highlight
          text={doc.why_features[0].feature}
          coordinate={width > 1024 ? ["5px", 0, 0, 0] : ["3px", 0, 0, "-3px"]}
          bottom
          left
          {...sal("right")}
        ></Highlight>

        <Highlight
          text={doc.why_features[4].feature}
          coordinate={
            width > 1024 ? ["183px", 0, 0, "74px"] : ["112px", 0, 0, "42px"]
          }
          bottom
          left
          {...sal("right")}
        ></Highlight>

        <Highlight
          text={doc.why_features[2].feature}
          coordinate={
            width > 1024 ? ["60px", "113px", 0, 0] : ["35px", "68px", 0, 0]
          }
          top
          right
          {...sal("left")}
        ></Highlight>

        <Highlight
          text={doc.why_features[3].feature}
          coordinate={
            width > 1024 ? [0, "76px", "0px", 0] : [0, "40px", "3px", 0]
          }
          bottom
          right
          {...sal("left")}
        ></Highlight>
      </TextWrapper>
    </Container>
  )
}

export default DesktopWhy

const TextWrapper = styled(RelativeDiv)`
  width: 550px;
  margin: 160px auto 200px;

  ${tablet} {
    width: 350px;
    margin: 100px auto 120px;
  }
`

const Texture = styled.img`
  width: 100%;
`
