import React from "react"
import styled from "styled-components"
import { Container } from "~components/Shared/Mixins"
import { RichText } from "prismic-reactjs"
import SectionTitle from "~components/Shared/SectionTitle"
import useWindowDimensions from "~utilities/getDimension"
import { useTransition, animated } from "react-spring"
import Arrow from "./Arrow"
import Indicators from "./Indicators"

const MobileProcess = ({ doc, activeIndex, handleClick, className }) => {
  const { width } = useWindowDimensions()
  const boxSize = width ? width - 88 - 24 : 375 - 88 - 24
  const positions = ["top", "right", "bottom", "left"]

  const transitions = useTransition(activeIndex, (item) => item, {
    from: { opacity: 0, transform: `translateX(10px)` },
    enter: { opacity: 1, transform: `translateX(0)` },
    leave: { opacity: 0, transform: `translateX(-10px)` },
  })

  return (
    <Wrapper className={className}>
      <Container>
        <SectionTitle title={doc.process_title} desc={doc.process_desc} />
        <ProcessWrapper size={boxSize}>
          {doc.processes.map((item, index) => (
            <React.Fragment key={item.title}>
              <StepIcon
                color={item.color}
                className={`${positions[index]} 
                ${index <= activeIndex && "active"}`}
                size={boxSize}
                onClick={() => handleClick(index)}
              >
                <span>{item.title}</span>
              </StepIcon>
              <Arrow size={boxSize} index={index} />
            </React.Fragment>
          ))}

          {transitions.map(
            ({ item, key, props }) =>
              activeIndex === item && (
                <Content size={boxSize} style={props} key={key}>
                  <p className="title">{doc.processes[item].title}</p>
                  <RichText render={doc.processes[item].desc} />
                </Content>
              )
          )}
        </ProcessWrapper>
        
        <Indicators activeIndex={activeIndex} />
      </Container>
    </Wrapper>
  )
}

export default MobileProcess

const bgColor = "#252634"

const Wrapper = styled.div`
  width: 100%;
  background-color: ${bgColor};
  padding-bottom: 40px;
`

const ProcessWrapper = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin: 44px auto 70px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StepIcon = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 200px;
  background-color: var(--color-bg-1);
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 37px;
  transition: background-color 0.8s, box-shadow 0.8s;
  will-change: background-color, box-shadow;

  &.top {
    top: -44px;
    left: ${({ size }) => size / 2 - 44}px;
  }

  &.right {
    right: -44px;
    top: ${({ size }) => size / 2 - 44}px;
  }

  &.bottom {
    bottom: -44px;
    left: ${({ size }) => size / 2 - 44}px;
  }

  &.left {
    left: -44px;
    top: ${({ size }) => size / 2 - 44}px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: var(--color-white);
    margin: 0 0 55px;
    user-select: none;
  }

  &.active {
    background-color: ${(props) => props.color};
    box-shadow: 0 4px 8px 0
      ${(props) => {
        const regex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
        const result = regex.exec(props.color)
        return result
          ? `rgba(${parseInt(result[1], 16)}, 
          ${parseInt(result[2], 16)}, 
          ${parseInt(result[3], 16)}, 0.48)`
          : null
      }};
  }
`

const Content = styled(animated.div)`
  width: ${({ size }) => size - 88 - 36}px;
  text-align: center;

  p {
    font-size: 12px;
    margin-bottom: 3px;

    &.title {
      color: var(--color-white);
      font-weight: bold;
      margin-bottom: 6px;
    }
  }
`
