import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Loadable from "@loadable/component"
import { RichText } from "prismic-reactjs"
import { mobile, tablet } from "~utilities/mediaQuery"
import Icon from "~assets/icon"
import Button from "~components/Shared/Button"
import Background from "./Background"
import useWindowDimensions from "~utilities/getDimension"
import { scrollTo, scrollToEleById } from "~utilities/scroll"

const Globe = Loadable(() => import("./Globe"))

const Header = () => {
  // fetch data
  const {
    prismic: {
      homepage: { edges },
    },
  } = useStaticQuery(
    graphql`
      query {
        prismic {
          homepage: allHomepages {
            edges {
              node {
                slogan
                connect_cta
                about_moozy
              }
            }
          }
        }
      }
    `
  )
  const doc = edges[0] && edges[0].node
  if (!doc)
    throw Error("Cannot Fetch Nodes from Header Component, Check CMS Source.")

  const handleClick = () => {
    scrollToEleById("anchor-start", -70)
  }

  const { height } = useWindowDimensions()
  const scrollToService = () => scrollTo(height)

  return (
    <Section>
      <Content>
        <Icon
          icon="logo"
          width="105"
          style={{ marginBottom: "10px" }}
          data-sal="slide-up"
          data-sal-delay="999"
          data-sal-duration="2000"
        />
        <h1 data-sal="slide-up" data-sal-delay="999" data-sal-duration="2000">
          {doc.slogan}
        </h1>
        <Button
          theme="red"
          content={doc.connect_cta}
          icon="comment"
          onClick={handleClick}
          data-sal="slide-up"
          data-sal-delay="999"
          data-sal-duration="2000"
        />
      </Content>
      <div className="mobileContent">
        <About>
          <Icon icon="smile" width="15" />
          <h5 style={{ margin: "0" }}>
            About <strong>Moozy</strong>
          </h5>
          <Intro>{RichText.render(doc.about_moozy)}</Intro>
        </About>
        <Scroll onClick={scrollToService}>
          <p>Scroll</p>
          <Icon icon="double-down" width="10" />
        </Scroll>
      </div>
      <Globe />
      <Background />
    </Section>
  )
}

export default Header

const Section = styled.section`
  position: relative;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  ${mobile} {
    & .mobileContent {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 12px;
      left: 24px;
    }
  }
`

const Content = styled.div`
  width: 75%;
  max-width: 1100px;
  margin: 0 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mobile} {
    margin: 0 24px;
  }
`

const About = styled.div`
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 800px;
  margin-bottom: 15px;

  & p {
    margin: 0;
  }

  ${tablet} {
    width: 75%;
  }

  ${mobile} {
    width: 80%;
    position: relative;
    left: 0;
    margin: 0;
  }
`

const Intro = styled.h5`
  color: var(--color-text);

  & strong {
    color: var(--color-white);
  }
`

const Scroll = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 20px;
  border-left: 1px var(--color-text) solid;
  padding: 5px;
  writing-mode: vertical-rl;
  font-size: 14px;
  cursor: pointer;

  svg {
    animation: pulsate 4s linear forwards infinite;
  }

  @keyframes pulsate {
    0%,
    100% {
      opacity: 0.2;
      transform: translateY(0);
    }
    35%,
    65% {
      opacity: 0.8;
      transform: translateY(5px);
    }
  }

  ${mobile} {
    position: relative;
    bottom: 0;
    padding: 3px;
    font-size: 12px;
    justify-content: space-between;
  }
`
