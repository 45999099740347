/*
 * Services Card, used in both Desktop and Mobile view
 */

import React from "react"
import { RichText } from "prismic-reactjs"
import styled from "styled-components"
import Icon from "~assets/icon"

const Card = ({ item, mask, ...props }) => {
  const { title, desc, assets } = item

  return (
    <Wrapper mask={mask} {...props}>
      <IconWrapper color={assets.color}>
        <Icon icon={assets.icon} width="32" />
      </IconWrapper>
      <Title>{title}</Title>
      <Desc>{RichText.asText(desc)}</Desc>
      <Texture
        src={require(`../../../assets/images/services/${assets.texture}`)}
        alt="Textures"
      />
    </Wrapper>
  )
}

const ServiceCard = ({ ...props }) => (
  <ServicesWrapper {...props}>
    <Card item={props.item} mask={props.mask} />
    <ReflectWrapper>
      <ReflectCard item={props.item} />
      <ReflectGradient />
    </ReflectWrapper>
  </ServicesWrapper>
)

export default ServiceCard

const Wrapper = styled.div`
  position: relative;
  width: 280px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: radial-gradient(
    67.06% 67.06% at 15.55% 0%,
    #3d3d47 0%,
    #2a2b33 100%
  );
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  padding: 12px;
  overflow: hidden;

  ${(props) =>
    props.mask &&
    `
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(33, 33, 42, 0.6);
    }
  `}
`

const Title = styled.h4`
  color: var(--color-white);
  margin: 30px 0 5px;
`

const Desc = styled.span`
  display: block;
  font-family: "SF Pro", Arial, Helvetica, sans-serif;
  line-height: 1.3;
  max-width: 200px;
  margin-bottom: 8px;
  z-index: 1;
`

const Texture = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;
`

const ServicesWrapper = styled.div`
  position: relative;
  transition: filter 0.5s;
  filter: grayscale(40%);
    
  &:hover {
    /* transform: scale(1.01); */
    filter: grayscale(0%);
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 42px;
  height: 42px;
  background: ${(props) => props.color};
  border-radius: 3px;
`

const ReflectWrapper = styled.div`
  position: absolute;
  max-height: 95px;
  width: 280px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px var(--color-bg-1);
  margin-top: 0 !important;
  transform: translateY(-8px);
  z-index: -3;

  @media (min-width: 2000px) {
    box-shadow: 0px 0px 10px 0px #21212c;
  }
`

const ReflectGradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, transparent, var(--color-bg-1) 100%);

  @media (min-width: 2000px) {
    background: linear-gradient(180deg, transparent, #21212c 100%);
  }
`

const ReflectCard = styled(Card)`
  transform: scaleY(-1);
  filter: blur(8px);
`
